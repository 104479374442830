#ppcity-map{
    height: 100%;
    width: 100%;
}
.ol-control button {
    background-color: rgba(95, 158, 160, 0.8);
}

.ol-control button:hover {
    background-color: rgba(95, 158, 160, 1);
}

.ol-control-popup {
    position: absolute;
    right: 0.5em;
    text-align: left;
    top: 0.5em;
    font-size: smaller;
    overflow-y: auto;
    max-height: 100%;
}

.ol-control button:hover, .ol-control button:focus {
    text-decoration: none;
    background-color: rgba(95, 158, 160,0.7);
}

.ol-layerswitcher button {
    background-color: rgba(95,158,160,0);
    float: right;
    z-index: 10;
    position: relative;
    font-size: 1.7em;
}

.ol-layerswitcher button:hover, .ol-layerswitcher button:focus {
    text-decoration: none;
    background-color: rgba(95,158,160,0);
}

.ol-layerswitcher button:focus {
     outline: unset;
}

.ol-full-screen {
    right: unset;
    left: .5em;
    top: 5em;
}

.ol-search {
    top: 10em;
    left: 0.5em;
}

.ol-search button {
    width: 2em;
    height: 2em;
    font-size: smaller;
}

